import { Grid, Button, Typography } from '@mui/material';
import AuthWrapper from 'sections/auth/AuthWrapper';
import AnimateButton from 'components/@extended/AnimateButton';

const CheckoutSuccess = () => {
    return (
        <AuthWrapper>
            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <Typography sx={{ fontSize: 23, fontWeight: 'bold' }}>Obrigado por escolher Altern.</Typography>
                    <Typography sx={{ fontSize: 15, color: '#837c84' }}>Enviamos instruções de autenticação para seu e-mail!</Typography>

                    <AnimateButton>
                        <Button fullWidth size="large" variant="contained" color="primary" style={{ backgroundImage: 'linear-gradient(310deg, #7928ca, #ff0080)', color: '#FFF', textTransform: 'none', marginTop: 20 }} onClick={() => window.location="/login"}>
                            Voltar ao login
                        </Button>
                    </AnimateButton>
                </Grid>
            </Grid>
        </AuthWrapper>
    );
};

export default CheckoutSuccess;
