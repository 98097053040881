import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { Button, Stack, Table, TableCell, TableBody, TableHead, TableRow, TableContainer, Tooltip, InputAdornment, TextField, Grid, Box, Avatar, Chip, Divider, Typography, List, ListItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Modal, Switch, FormControlLabel, CircularProgress } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import MainCard from 'components/MainCard';
import 'react-toastify/dist/ReactToastify.css';
import { ArrowDown2, SearchNormal1, Eye } from 'iconsax-react';
import { useReactTable, getCoreRowModel, getPaginationRowModel } from '@tanstack/react-table';
import Loader from 'components/Loader';
import { TablePagination } from 'components/third-party/react-table';
import IconButton from 'components/@extended/IconButton';
import Api from 'services/Api';

const Influencers = () => {
  const navigate = useNavigate();
  const [influencers, setInfluencers] = useState([]);
  const [expandedRowId, setExpandedRowId] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [globalFilter, setGlobalFilter] = useState('');
  
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [selectedInfluencer, setSelectedInfluencer] = useState(null);

  const userData = localStorage.getItem('user');
  const user = userData ? JSON.parse(userData) : null;
  const user_id = user ? user.id : null;
  const token = localStorage.getItem('token');

  const handleCreateOpenDialog = () => setCreateDialogOpen(true);
  const handleCreateCloseDialog = () => setCreateDialogOpen(false);

  const handleUpdateOpenDialog = (influencer) => {
    setSelectedInfluencer(influencer);
    setUpdateDialogOpen(true);
  };

  const handleUpdateCloseDialog = () => {
    setUpdateDialogOpen(false);
    setSelectedInfluencer(null); 
  };

  useEffect(() => {
    fetchInfluencers();
    setLoading(false);
  }, [pageIndex, pageSize]);

  const toggleRowExpansion = (rowId) => {
    setExpandedRowId(expandedRowId === rowId ? null : rowId);
  };

  const fetchInfluencers = async () => {
    try {
      if (!user_id || !token) return;
  
      const response = await Api.get(`/influencers`, {
        params: { user_id },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      const sortedInfluencers = response.data.sort((a, b) => b.status - a.status);
      setInfluencers(sortedInfluencers);
  
    } catch (error) {
      console.error('Error fetching influencers:', error);
    }
  };

  const columns = useMemo(() => [
    {
      header: 'Nome',
      accessorKey: 'name',
      cell: ({ getValue }) => <strong>{getValue()}</strong>,
    },
    {
      header: 'Mensagem',
      accessorKey: 'message',
      cell: ({ getValue }) => <strong>{getValue()}</strong>,
    },
    {
      header: 'Instagram',
      accessorKey: 'instagram',
      cell: ({ getValue }) => <div>{getValue()}</div>,
    },
    {
      header: 'Link',
      accessorKey: 'link',
      cell: ({ getValue }) => <div>https://altern.com.br/i/{getValue()}</div>,
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: ({ getValue }) => (
        <div style={{ color: getValue() ? 'green' : 'red' }}>
          {getValue() ? 'Ativo' : 'Inativo'}
        </div>
      ),
      sortingFn: 'basic',
    },
    {
      header: 'Ações',
      accessorKey: 'actions',
      cell: ({ row }) => (
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
          <Tooltip title="Editar">
            <IconButton color="secondary" onClick={() => handleUpdateOpenDialog(row.original)}>
              <Eye />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
  ], [expandedRowId]);

  const table = useReactTable({
    data: influencers,
    columns,
    state: {
      pageIndex,
      pageSize,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    pageCount,
    manualPagination: true,
    enableSorting: true,
    initialState: {
      sorting: [{ id: 'status', desc: true }],
    },
  });

  const CreateInfluencerDialog = () => {
    const [formData, setFormData] = useState({
      user_id: user_id,
      name: '',
      instagram: '',
      message: ''
    });

    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if (!createDialogOpen) {
        setFormData({
          user_id: user_id,
          name: '',
          instagram: '',
          message: ''
        });
      }
    }, [createDialogOpen, user_id]);

    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    };

    const handleSubmit = async () => {
      if (!formData.name || !formData.instagram) {
        toast.error('Por favor, preencha os campos obrigatórios.');
        return;
      }

      setLoading(true);
      try {
        const response = await Api.post('/influencers', formData);

        if (response.status !== 200 && response.status !== 201) {
          toast.error('Falha ao criar o link de influenciador...');
          return;
        }

        toast.success('Influenciador criado com sucesso!');
        onCreate();
        handleCreateCloseDialog();
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      } finally {
        setLoading(false);
      }
    };

    return (
      <Dialog open={createDialogOpen} onClose={handleCreateCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>Criar influenciador</DialogTitle>
        <DialogContent>
          <Stack spacing={2} mt={2}>
            <TextField
              name="name"
              label="Nome"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              required
            />
            <TextField
              name="instagram"
              label="Instagram"
              value={formData.instagram}
              onChange={handleChange}
              fullWidth
              required
            />
            <TextField
              name="message"
              label="Mensagem"
              value={formData.message}
              onChange={handleChange}
              fullWidth
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateCloseDialog} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Criar'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const UpdateInfluencerModal = ({ open, onClose, influencerData, onUpdate }) => {
    const token = localStorage.getItem('token');
    const [status, setStatus] = useState(influencerData?.status || 0);
    const [formData, setFormData] = useState({
      message: influencerData?.message || '',
      status: influencerData?.status || 0,
      name: influencerData?.name || '',
      instagram: influencerData?.instagram || '',
    });

    const [confirmOpen, setConfirmOpen] = useState(false); 
    const [newStatus, setNewStatus] = useState(status);

    useEffect(() => {
      setFormData({
        message: influencerData?.message || '',
        status: influencerData?.status || 0,
        name: influencerData?.name || '',
        instagram: influencerData?.instagram || '',
      });
      setStatus(influencerData?.status || 0); 
    }, [influencerData]);

    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    };

    const handleStatusChange = () => {
      const toggledStatus = status === 1 ? 0 : 1;
      setNewStatus(toggledStatus); 
      setConfirmOpen(true); 
    };

    const handleConfirm = async () => {
      setConfirmOpen(false);
      setStatus(newStatus);
    
      try {
        const payload = {
          id: influencerData.id,
          user_id: influencerData.user_id,
          status: newStatus,
        };
    
        const token = localStorage.getItem('token');
    
        const response = await Api.put(`/influencers`, payload, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        if (response.status !== 200 && response.status !== 204) {
          throw new Error('Failed to update status...');
        }
    
        toast.success('Status atualizado com sucesso!');
        onUpdate(); 
        onClose();
      } catch (error) {
        toast.error(error.response?.data?.message || 'Failed to update status.');
        setStatus(newStatus === 1 ? 0 : 1);
      }
    };

    const handleCancel = () => {
      setConfirmOpen(false);
    };

    const constructPayload = () => {
      const payload = {
        id: influencerData.id,
        user_id: influencerData.user_id,
      };

      if (formData.message) payload.message = formData.message;
      if (formData.name) payload.name = formData.name;
      if (formData.instagram) payload.instagram = formData.instagram;

      return payload;
    };

    const handleSubmit = async () => {
      try {
        const payload = constructPayload();

        const response = await Api.put(`/influencers`, payload);

        if (response.status !== 200 && response.status !== 204) {
          throw new Error('Falha ao atualizar o influencer...');
        }

        toast.success('Influencer atualizado com sucesso!');
        onUpdate();
        onClose(); 
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    return (
      <>
        <Modal open={open} onClose={onClose}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 600,
              bgcolor: 'background.paper',
              borderRadius: 2,
              p: 3,
              boxShadow: 24,
            }}
          >
            <Typography variant="h6" component="h2">
              Editar Influencer
            </Typography>
            <Stack spacing={2} mt={2}>
              <TextField
                name="name"
                label="Name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                name="instagram"
                label="Instagram"
                value={formData.instagram}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                name="message"
                label="Message"
                value={formData.message}
                onChange={handleChange}
                fullWidth
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={status === 1} 
                    onChange={handleStatusChange}
                  />
                }
                label={status === 1 ? 'Ativo' : 'Inativo'}
              />
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                Salvar alterações
              </Button>
            </Stack>
          </Box>
        </Modal>

        <Dialog open={confirmOpen} onClose={handleCancel}>
          <DialogTitle style={{ fontWeight: 'bold', fontSize: 18 }}>Confirmar a mudança de status</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Você tem certeza de que deseja alterar o status de 
              (<strong style={{ fontSize: 15 }}>{formData.name}</strong>) para{' '}
              <strong>{newStatus === 1 ? 'Ativo' : 'Inativo'}</strong>? 
              <br />
              <br />
              {
                formData.status == 0 ? (
                  <>
                    <strong>Observação:</strong> 
                    <br />
                      Ao ativar o link deste influenciador, os demais links serão desativados.
                      O seu plano permite apenas um link por conta.
                  </>
                ) : null
              }
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCancel} color="secondary">
              Cancelar
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  return (
    <>
      <MainCard content={false}>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" sx={{ padding: 3 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} xl={2}>
              <TextField
                placeholder="Nome do influencer"
                onChange={(e) => setGlobalFilter(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ArrowDown2 />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={2}>
              <Button variant="contained" startIcon={<SearchNormal1 />} size="large" style={{ marginTop: 6 }}>
                Pesquisar
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} xl={2}>
              <Button variant="contained" color="primary" onClick={handleCreateOpenDialog} size="large" style={{ marginTop: 6 }}>
                Criar influenciador
              </Button>
            </Grid>
          </Grid>
        </Stack>

        {loading ? (
          <Loader />
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <TableCell key={header.id}>
                        {header.isPlaceholder ? null : header.column.columnDef.header}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {table.getRowModel().rows.map((row) => (
                  <Fragment key={row.id}>
                    <TableRow>
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id}>
                          {cell.column.columnDef.cell(cell.getContext())}
                        </TableCell>
                      ))}
                    </TableRow>
                    {expandedRowId === row.original.id && (
                      <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                        <TableCell colSpan={columns.length}>
                          <CustomerView data={row.original} />
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Box sx={{ p: 2 }}>
          <TablePagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            pageCount={pageCount}
          />
        </Box>
      </MainCard>
      <ToastContainer />

      <CreateInfluencerDialog />

      {selectedInfluencer && (
        <UpdateInfluencerModal
          open={updateDialogOpen}
          onClose={handleUpdateCloseDialog}
          influencerData={selectedInfluencer}
          onUpdate={() => {
            fetchInfluencers();
            handleUpdateCloseDialog();
          }}
        />
      )}
    </>
  );
};

export default Influencers;
