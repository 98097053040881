import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// third-party
import { Provider as ReduxProvider } from 'react-redux';

// fonts
import 'assets/fonts/inter/inter.css';

// scroll bar
import 'simplebar/src/simplebar.css';

// apex-chart
import 'assets/third-party/apex-chart.css';

// project-imports
import App from './App';
import { store } from 'store';
import { APP_BASE_NAME } from './config';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ReduxProvider store={store}>
    <BrowserRouter basename={APP_BASE_NAME}>
      <App />
    </BrowserRouter>
  </ReduxProvider>
);
